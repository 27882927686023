import * as React from "react";
import { Helmet } from "react-helmet";
import Landing from "../../nav/en-projects/Landing";
import LayoutProjects from "../../components/en/LayoutProjects";

const IndexPage = () => {
  return (
    <LayoutProjects>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="mathiasbensimon,anamonso,ghostgalerie,parisexhibition,expositionparis,artcontemporainparis,contemporaryartgalleryparis" />
      </Helmet>
      <Landing />
    </LayoutProjects>
  );
};

export default IndexPage;
