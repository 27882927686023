import * as React from "react";
import { Wrapper, ListWrapper, ButtonWrapper,Heigh } from "./Landing.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";
import Button from "./../../../components/Button";
import DondiWhite from "./../../../res/Dondi/exposition/dondi-tile.png";
import NielsShoeMeulman from "../../../res/shoe/expo/shoe-tile.png"
import Aone from "./../../../res/Aone/exposition/aone-tile.png"
import Daze from "./../../../res/Daze/exposition/daze-tile.png"
import KoolKoor from "./../../../res/KoolKoor/expo/koolkoor-tile.png"
import EventCardExpo from "../../../components/EventCardExpo";
import CoolTabs from 'react-cool-tabs';
import { useState } from "react";
import NF1 from "../../../res/Nos fantomes/fantomes-tile.png"
import PhantomV2 from "../../../res/Nos fantomes V2/fantomes-v2-tile.png"
import StudioVsVd from "../../../res/studiovsvandalism/studio-vs-vandalism-tile.png"
import MrsMoment from "../../../res/landing/tile-expo-marseille.png"
import PrsMoment from "../../../res/landing/tile-expo-paris.jpeg"
import NF2 from "../../../res/landing/winter-tile.png"
import NF3 from "../../../res/New York Street Style/cover.png"
import VIRTUAL from "../../../res/landing/virtual-visit.png"
import GDTILE from "../../../res/graffitiDynamics/tile.png"
import EPODETILE from "../../../res/epode/epode.jpg"
import RTILE from "../../../res/Reminiscence/tile.png"


const passedExhibitions = [
  {
    date: "October 13th - November 18th 2023",
    title: "REMINISCENCE",
    link: "reminiscence",
    artist: "",
    place: "Paris",
    description:
    "Ghost Galerie is pleased to present the second exhibition in its program dedicated to the emerging art scene: Ghost Project. Reminiscence, running from October 13th to November 18th, will present the work and universe of 5 artists: Hakim Sahiri, Clémence Gbonon, Djabril Boukhenaïssi, Félix Taburet & Hannah Becquante",
    presentation_photo: RTILE,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "April 7th - June 3rd 2023",
    title: "EPODE",
    link: "epode",
    artist: "ANA MONSÓ & MATHIAS BENSIMON",
    place: "Paris",
    description:
    "Ghost Galerie is pleased to present its new exhibition: Epode, from April 7 to May 13, 2023. A new chapter in its history, this event is part of a new exhibition program - Ghost Project - highlighting the emerging contemporary art scene. Opening on April 6 from 5pm in the presence of the artists.",
    // "Ghost Galerie a le plaisir de vous présenter sa nouvelle exposition : Épode, du 7 avril au 13 Mai 2023 > Prolongation jusqu'au 3 juin!. \nNouveau chapitre de son histoire, cet événement s’inscrit dans un nouveau programme d’exposition - Ghost Project - mettant en lumière la scène artistique émergente contemporaine.",
    // "La Ghost Galerie est heureuse de vous présenter son nouvel accrochage : A MOVEMENT IN MOTION. Pour ce nouvel accrochage hivernal, les oeuvres bougeront entre Paris et Marseille et inversement, Elles apparaîtrons ou disparaîtront sans prévenir ! Une double exposition qui nous permet de vous proposer entre Paris et Marseille plus d'une cinquantaine d'oeuvres des années 80 à nos jours de plusieurs artistes, Dévoilant ainsi au regard du visiteur novice ou averti, le fondement même de ce mouvement artistique : le mouvement en mouvement.",
    presentation_photo: EPODETILE,
    photos_paths: ["path", "path", "path", "path"],
  },
];

var deviceWidth = 0;
if (typeof window !== 'undefined') {
  deviceWidth = window.innerWidth;
}

// TODO 430 Plus grosse width de telephone
var test = deviceWidth < 430 ? 320 : 375;

const Landing = () => {
  const [left, setLeft] = useState(true)
  const [right, setRight] = useState(false)
  // setTrueHeight([actualExhibitions.length * test]);
  
  return (
    <div id='expo' className={Wrapper}>
      <h1 className={GrayTitle} style={{marginTop:"60px", marginBottom:"50px"}}>GHOST PROJECTS EXHIBITIONS</h1>
      {passedExhibitions.map((exhibition, i = 0) => (
            <EventCardExpo
              date={exhibition.date}
              title={exhibition.title}
              link={"/en-projects/expositions/" + exhibition.link}
              place={exhibition.place}
              description={exhibition.description}
              photo={exhibition.presentation_photo}
              artist={exhibition.artist}
              index={i}
              lenght={passedExhibitions.length}
            />
          ))}
      <div className={ButtonWrapper}>
        <Button
          alt=""
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location.href = "/en/contactez-nous";
            }
          }}
        >
          Contact-us
        </Button>
      </div>
    </div>
  );
};

export default Landing;
